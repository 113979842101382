import React, {useEffect} from 'react';
import { Link } from 'react-scroll';
import Aos from 'aos';
import 'aos/dist/aos.css';
import CarouselStack from './Atoms/CarouselStack';
import OfferEng from './Atoms/OfferEng';

export default function CarouselComponent() {
    useEffect(() => {
        Aos.init({duration: 3000});
    }, [])
    return (
        <>
            <div className='header-space'>
            </div>
                    <Link to='technology' activeClass='active' spy={true} smooth={true}
                          duration={500} offset={-50}>
                        <div className='carousel-scroll'>
                        </div>
                    </Link>
            <div className='carousel-text' name='technology'>
                <p data-aos='fade-right'>stack</p>
            </div>
            <CarouselStack/>
            <div className='header-space'>
            </div>
            <section className='section-about'>
                <div className='section-text'>
                    <p data-aos='fade-right' className='section-text-tittle'>About us</p>
                    <p data-aos='fade-right'>
                        We are a company that provides software developing services. We are open to
                        working with you. We love what we do, because programming is our passion.</p>
                </div>
                <OfferEng/>
            </section>
        </>
    );
}