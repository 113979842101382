import React from 'react';
import Nav from './Nav';
import Header from './Header'
import Main from './Main';
import CarouselComponent from './CarouselComponent';
import Footer from './Footer';

function Home() {
    return (
        <>
            <Nav/>
            <Header/>
            <CarouselComponent/>
            <Main/>
            <Footer/>
        </>
    )
}

export default Home;
