import React from 'react';
import {useRef} from 'react'
import {NavLink} from 'react-router-dom';
import  { useOutsideClick } from './UseOutsideClick'

const NavItemLangEng = (props) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
        <ul className='nav-main-lang'>
            <li>
                <NavLink to='/home' exact={true} activeClassName='active' ref={dropdownRef}>
                <a href="#" className='nav-click' onClick={onClick}>
                    eng <img src={require('../../img/eng.png')} alt="eng"/>
                </a>
                </NavLink>
            </li>
            {isActive && props.children}
        </ul>
    )
}
export default NavItemLangEng;
