import React from 'react';

function Footer() {
    return (
        <>
            <footer>
                <p>&#xA9; TigerSoft </p>
            </footer>
        </>
    )
}
export default Footer;
