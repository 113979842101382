import React from 'react';
import {useRef} from 'react'
import {NavLink} from 'react-router-dom';
import  { useOutsideClick } from './UseOutsideClick'

const NavItemLang = (props) => {
    const dropdownRef = useRef(null);
    const [isActive, setIsActive] = useOutsideClick(dropdownRef, false);
    const onClick = () => setIsActive(!isActive);

    return (
        <ul className='nav-main-lang'>
            <li>
                <NavLink to='/' exact={true} activeClassName='active' ref={dropdownRef}>
                <a href="#" className='nav-click' onClick={onClick}>
                    pl <img src={require('../../img/poland.png')} alt="pl"/>
                </a>
                </NavLink>
            </li>
            {isActive && props.children}
        </ul>
    )
}
export default NavItemLang;
