import React from 'react';
import {NavLink} from 'react-router-dom';

const DropdownEng = () => {
    const DropdownItem = (props) => {
        return (
            <a href="#" className='nav-click'>
                {props.children}
            </a>
        )
    }
    return (
        <div className='dropdown'>
            <NavLink to='/' exact={true} activeClassName='active' className='dropdown-link'><DropdownItem>Pl <img
                src={require('../../img/poland.png')} alt="pl"/></DropdownItem></NavLink>
        </div>
    )
}
export default DropdownEng