import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';

export default function CarouselStack() {
    return (

    <div className="carousel-wrapper">
        <Carousel infiniteLoop useKeyboardArrows autoPlay showArrows onSwipeMove interval={4000}>
            <div>
                <img src={require('../../img/skills/java.png')}/>
                <img src={require('../../img/skills/angular.png')}/>
                <img src={require('../../img/skills/css.png')}/>
                <img src={require('../../img/skills/html.png')}/>
            </div>
            <div>
                <img src={require('../../img/skills/hibernate.png')}/>
                <img src={require('../../img/skills/javascript.png')}/>
                <img src={require('../../img/skills/react.png')}/>
                <img src={require('../../img/skills/mongodb.png')}/>
            </div>
            <div>
                <img src={require('../../img/skills/postgres.png')}/>
                <img src={require('../../img/skills/spring.png')}/>
                <img src={require('../../img/skills/typescript.png')}/>
            </div>
        </Carousel>
    </div>
    )
}