import React from 'react';

function MainEng() {
    return (
        <>
            <div className='main-img'>
                <div data-aos='fade-right' className='main-text-wrapper'>
                    <p className='main-text'>Choose a job you love, and you will never have to work a day in your life. </p>
                    <p className='main-author'>Confucius</p>
                </div>
            </div>
        </>
    )
}

export default MainEng;