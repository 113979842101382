import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import NavEng from './NavEng';
import Footer from './Footer';
import Aos from 'aos'
import 'aos/dist/aos.css'
import emailjs from 'emailjs-com';
import { EmailjsSettings } from '../emailjs';

function ContactEng() {
    const {register, handleSubmit, errors, formState} = useForm();
    const onSubmit = (data, e) => {
        const { accountId, serviceId, templateId } = EmailjsSettings;
        emailjs.sendForm(serviceId, templateId, e.target, accountId)
            .then((result) => {
                console.log(result.text);
                alert('Form sent');
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
    }
    useEffect(() => {
        Aos.init({duration: 3000});
    }, [])
    return (
        <>
            <div className='contact-wrapper'>
            <NavEng/>
            <div className='contact'>
                <div className='contact-left'>
                </div>
                <div data-aos='fade-up' className='contact-main'>
                    <h1 data-aos='fade-up'>Contact us</h1>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <label data-aos='fade-up' data-aos-delay='500'>Name
                            <input
                                ref={register({
                                    required: true,
                                    minLength: 2,
                                    pattern: /^[A-Za-z]+$/i,
                                })}
                                type='text'
                                name='name'/>
                            {errors.name && <p>Required</p>}
                        </label>
                        <label data-aos='fade-up' data-aos-delay='1000'>E-mail
                            <input
                                ref={register({
                                    required: true,
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: 'Invalid e-mail',
                                    }
                                })}
                                style={{borderColor: errors.username && "red"}}
                                type='email'
                                name='email'/>
                            {errors.email && <p>Required</p>}
                        </label>
                        <label data-aos='fade-up' data-aos-delay='1500'>Message
                            <textarea
                                name='message'
                                ref={register({
                                    required: true,
                                    minLength: 2,
                                })}>
                            </textarea>
                        </label>
                        <button type='submit' data-aos='fade-up' data-aos-delay='2000' className='btn-send'
                                disabled={formState.isSubmitting}>Message
                        </button>
                    </form>
                </div>
                <div className='contact-right'>
                </div>
            </div>
            <Footer className='contact-footer'/>
            </div>
        </>
    )
}
export default ContactEng;
