import React from 'react';
import {NavLink} from 'react-router-dom';
import NavItemLangEng from './Atoms/NavItemLangEng';
import DropdownEng from './Atoms/DropDownEng';
import 'aos/dist/aos.css';

const list = [
    {name: 'home', path: '/home', exact: true},
    {name: 'contact', path: '/contactus', exact: true},
]

const NavEng = (props) => {
    const menu = list.map(item => (
            <li key={item.name}>
                <NavLink to={item.path} exact={item.exact ? item.exact : false } activeClassName='active'>{item.name}</NavLink>
            </li>
        )
    )
    return (
        <nav>
            <div className='nav-logo'>
                <p>
                    <span>t</span><span>i</span><span>g</span><span>e</span><span>r</span><span>s</span><span>o</span><span>f</span><span>t</span>
                </p>
            </div>
            <div className='nav-main'>
                <ul className='nav-main-menu'>
                    {menu}
                </ul>
                <NavItemLangEng>
                    <DropdownEng/>
                </NavItemLangEng>
            </div>

        </nav>
    )
}
export default NavEng;