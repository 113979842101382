import React from 'react';
import NavEng from './NavEng';
import HeaderEng from './HeaderEng'
import MainEng from './MainEng';
import CarouselComponentEng from './CarouselComponentEng';
import Footer from './Footer';

function HomeEng() {
    return (
        <>
            <NavEng/>
            <HeaderEng/>
            <CarouselComponentEng/>
            <MainEng/>
            <Footer/>
        </>
    )
}

export default HomeEng;
