import React from 'react';

function Main() {
    return (
        <>
            <div className='main-img'>
                <div data-aos='fade-right' className='main-text-wrapper'>
                    <p className='main-text'>wybierz pracę, którą kochasz, a nie będziesz musiał pracować nawet przez
                        jeden dzień w swoim życiu </p>
                    <p className='main-author'>Konfucjusz</p>
                </div>
            </div>

        </>
    )
}

export default Main;