import React from 'react';

function Header() {
    return (
        <>
            <div  className='header-img'>
            </div>
            <div className='header-text'>
                <p>software <span>development</span></p>
            </div>
        </>
    )
}
export default Header;