import React, {useEffect} from 'react';
import { Link } from 'react-scroll';
import Aos from 'aos';
import 'aos/dist/aos.css';
import CarouselStack from './Atoms/CarouselStack';
import Offer from './Atoms/Offer';

export default function CarouselComponent() {
    useEffect(() => {
        Aos.init({duration: 3000});
    }, [])
    return (
        <>
            <div className='header-space'>
            </div>
                    <Link to='technology' activeClass='active' spy={true} smooth={true}
                          duration={500} offset={-50}>
                        <div className='carousel-scroll'>
                        </div>
                    </Link>
            <div className='carousel-text' name='technology'>
                <p data-aos='fade-right'>technologia</p>
            </div>
            <CarouselStack/>
            <div className='header-space'>
            </div>
            <section className='section-about'>
                <div className='section-text'>
                    <p data-aos='fade-right' className='section-text-tittle'>O nas</p>
                    <p data-aos='fade-right'>Jesteśmy firmą świadczącą usługi programistyczne. Działamy na zlecenie i
                        jesteśmy otwarci na
                        współpracę z Tobą. Kochamy to co robimy, bo programowanie jest naszą pasją.</p>
                </div>
                <Offer/>
            </section>
        </>
    );
}