import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import Home from './Home';
import HomeEng from './HomeEng';
import Contact from './Contact';
import ContactEng from './ContactEng';
import './Home.scss';

function App() {
  return (
<Router>
  <Route exact path="/" component={Home}/>
  <Route exact path="/home" component={HomeEng}/>
  <Route exact path="/contact" component={Contact}/>
  <Route exact path="/contactus" component={ContactEng}/>
  </Router>
  );
}
export default App;
