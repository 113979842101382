import React from 'react';
import {NavLink} from 'react-router-dom';

const Dropdown = () => {
    const DropdownItem = (props) => {
        return (
            <a href="#" className='nav-click'>
                {props.children}
            </a>
        )
    }
    return (
        <div className='dropdown'>
            <NavLink to='/home' exact={true} activeClassName='active' className='dropdown-link'><DropdownItem>Eng <img
                src={require('../../img/eng.png')} alt="eng"/></DropdownItem></NavLink>
        </div>
    )
}
export default Dropdown