import React from 'react';

export default function Offer () {

return (
<div className='section-wrapper'>
    <div data-aos='fade-up' data-aos-easing='linear' data-aos-duration='2000' className='section-item'>
        <img src={require('../../img/smartphone.png')}/>
        <h3>Aplikacje mobilne</h3>
    </div>
    <div data-aos='fade-up' data-aos-easing='linear' data-aos-duration='2000' data-aos-delay='500'
         className='section-item'>
        <img src={require('../../img/screen.png')}/>
        <h3>Strony i aplikacje webowe</h3>
    </div>
    <div data-aos='fade-up' data-aos-easing='linear' data-aos-duration='2000' data-aos-delay='1000'
         className='section-item'>
        <img src={require('../../img/serce.png')}/>
        <h3>Tworzenie z pasją</h3>
    </div>
</div>
)
}